<template>
  <b-modal
    id="edit-client"
    size="md"
    title="Edit Client Details"
    ref="modal"
    @ok="handleOk"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />
    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Client" label-for="clientName">
            <b-form-input
              name="Client Name"
              type="text"
              v-model="form.clientName"
              v-validate="'required'"
              placeholder="Client Name"
              maxlength="25"
            />
            <span v-show="errors.has('Client Name')" class="help-block">
              {{ errors.first('Client Name') }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Image" label-for="image">
            <b-form-file
              id="image"
              v-model="selectedImage"
              placeholder="Choose image"
              ref="fileInput"
              accept="image/*"
              @change="onSelectImage"
              no-drop
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
            <p
              class="badge badge-danger bg-transparent ml-3"
              v-if="fileSizeExceeded"
            >
              File size exceeded the limit of {{ maxFileSize / 1000000 }} MB
            </p>
          </b-form-group>

          <!-- Preview Images -->
          <b-row v-if="image.url" class="form-row">
            <b-col md="12">
              <b-card
                overlay
                :img-src="image.url"
                img-alt="Card Image"
                text-variant="white"
              >
                <b-card-text class="text-right">
                  <base-button
                    type="danger"
                    icon="fa fa-trash"
                    size="sm"
                    @click="onRemoveImage"
                  />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>

          <b-row v-else-if="imagePreviewUrl" class="form-row">
            <b-col md="12">
              <b-card
                overlay
                :img-src="imagePreviewUrl"
                img-alt="Card Image"
                text-variant="white"
              ></b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <div class="row" v-if="uploading.uploadStatus === 'uploading'">
        <div class="col-md-12">
          <base-progress
            class="py-0 my-0"
            type="danger"
            :value="uploading.percentage"
            :label="uploading.filename"
            animated
          ></base-progress>
        </div>
      </div>
      <div class="row" v-else-if="uploading.uploadStatus === 'success'">
        <div class="col-md-12">
          <b-alert variant="success" class="py-2 my-0" show>
            <i class="fa fa-check"></i>
            &nbsp; Successfully saved!
          </b-alert>
        </div>
      </div>

      <base-button type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button
        type="primary"
        :disabled="isFileSizeExceeded"
        class="text-dark"
        @click="ok()"
      >
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// DAO
import clientDAO from '../../database/clients'
import { storage } from '../../config/firebase'

// Util
import dateUtil from '../../utils/dateUtil'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'edit-client',
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        clientName: '',
        image: {
          url: '',
        },
        isActive: true,
        createdBy: '',
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: '',
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },
      image: {},
      uploading: {
        filename: '',
        percentage: 0,
        uploadStatus: '', // [uploading, success, error]
      },

      // Check for loader
      isLoading: false,
      isUploading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
      maxFileSize: 2 * 1024 * 1024, //2MB
      selectedImage: null,
      fileSizeExceeded: false,
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
    imagePreviewUrl() {
      return this.form.image.url
    },
    isFileSizeExceeded() {
      return this.selectedImage && this.selectedImage.size > this.maxFileSize
    },
  },

  methods: {
    async handleOk(evt) {
      evt.preventDefault()

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          'Please address the field/s with invalid input.',
          'Warning',
        )
        this.isLoading = false
        return
      }

      await this.handleSubmit()
    },

    async handleSubmit() {
      try {
        this.isLoading = true

        // Update Timestamps
        this.form.updatedBy = this.loggedUser
        this.form.dateUpdated = dateUtil.getCurrentTimestamp()

        await clientDAO.updateClient(this.form, this.form.id)
        const docId = this.form.id
        console.log('finish saving data')

        if (this.image._isNew && !this.isFileSizeExceeded) {
          const imageFile = await this.firebaseUploadImage(this.image)
          await clientDAO.updateClient({ image: imageFile }, docId)
          console.log('Done uploading images')
        }

        this.uploadingInProgress('success')
        this.$toast.success(this.form.clientName + ' updated', 'Success!')
        this.$refs.modal.hide()
        bus.$emit('onCloseEditClient', this.form)

        console.log('done with all tasks')

        setTimeout(() => {
          this.resetUploadingState()
        }, 2000)
      } catch (error) {
        console.error(error)
        this.$toast.error(
          'There is an error updating client. Please try again.',
          'Error',
        )
      }

      // hide loading indicator
      this.isLoading = false
    },

    async firebaseUploadImage(image) {
      let filename = `CLIENT-${Date.now()}`
      this.uploadingInProgress('uploading', filename, 0)

      return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`images/clients/${filename}`)
        let task = storageRef.put(image.file)

        task.on(
          'state_changed',
          (snapshot) => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.uploadingInProgress('uploading', filename, percentage)
          },
          (error) => {
            reject(error)
            this.resetUploadingState()
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({
                name: filename,
                url: downloadURL,
              })
            })
          },
        )
      })
    },

    uploadingInProgress(uploadStatus, filename = '', percentage = 0) {
      this.uploading.filename = filename
      this.uploading.percentage = parseFloat(percentage.toFixed(2));
      this.uploading.uploadStatus = uploadStatus
    },
    resetUploadingState() {
      this.uploading = {
        filename: '',
        percentage: 0,
        uploadStatus: '',
      }
    },
    onRemoveImage() {
      this.image = ''
      this.$refs['fileInput'].reset()
    },
    onSelectImage(evt) {
      const file = evt.target.files[0]
      this.image = {
        url: URL.createObjectURL(file),
        file: file,
        _isNew: true,
      }
      if (file.size > this.maxFileSize) {
        this.fileSizeExceeded = true
        this.selectedImage = null // Clear the selected file
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        this.fileSizeExceeded = false
        // do something with the file
      }
      reader.readAsArrayBuffer(file)
    },
    onReset() {
      let client = this.$store.getters['client/currClient']
      let clientJSON = JSON.stringify(client)
      this.form = JSON.parse(clientJSON)

      // reset validation
      this.isLoading = false
      this.$validator.reset()
      this.errors.clear()
      this.onRemoveImage()

      this.images = {}
    },
  },
}
</script>
