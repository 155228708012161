<template>
  <div class="container-fluid">
    <b-card>
      <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

      <template v-slot:header>
        <i class="fa fa-users text-primary"></i>
        &nbsp;
        <strong>Clients</strong>
        &nbsp;
        <small>List of clients of EHI</small>
      </template>

      <b-card-text>
        <b-row class="mb-3">
          <b-col sm="6" md="3">
            <b-dropdown text=" Select Actions " variant="primary" slot="append">
              <b-dropdown-item v-b-modal.add-client>
                <i class="fa fa-plus"></i>
                Add Clients
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.reorder-project @click="eventBus.$emit('ReorderClientDialog', items)">
                <i class="fa fa-sort"></i>
                Re-order Clients
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="6" md="3" offset-md="6" class="text-md-right">
            <base-dropdown position="right">
              <base-button slot="title" type="primary" class="dropdown-toggle">
                Show {{ perPage }} / Page
              </base-button>
              <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
                {{ option }}
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
        </b-row>

        <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage"
          :per-page="perPage" responsive show-empty style="min-height: 380px">
          <template v-slot:cell(dateUpdated)="{ item }">
            {{ item.dateUpdated | dateAndTimeFormatter }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button title="Show/Hide Other Details" variant="success" @click.stop="row.toggleDetails" size="sm">
              <b-icon-eye />
            </b-button>

            &nbsp;
            <b-button title="Edit Client" size="sm" v-b-modal.edit-client v-b-tooltip.hover.top="'Edit Client Detail'"
              variant="info" @click.stop="updateSelClient(row.item)">
              <b-icon-pen />
            </b-button>
          </template>

          <template v-slot:cell(status)="row">
            <div>
              <a @click="updateSelClient(row.item)">
                <b-form-checkbox name="status" v-b-modal.activate-client v-model="row.item.isActive" switch>
                  {{ row.item.isActive ? "Active" : "Inactive" }}
                </b-form-checkbox>
              </a>
            </div>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-card>
              <b-tabs>
                <b-tab title="Image">
                  <b-row class="mx-2 my-3">
                    <b-input-group>
                      <b-col>
                        <img :src="row.item.image.url" style="width: 30%" class="img-fluid" alt="Image" />
                      </b-col>
                    </b-input-group>
                  </b-row>
                  <b-row class="mt-4 mb-2">
                    <b-col class="text-right">
                      <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                        size="sm">
                        <b-icon-arrow-up />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </b-table>
      </b-card-text>

      <template v-slot:footer>
        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="totalDisplay">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
    </b-card>

    <!-- Modals -->
    <ReorderClient />
    <AddClient />
    <EditClient />
    <ActivateClient />
  </div>
</template>

<script>
// components
import ReorderClient from './clients/ReorderClient'
import AddClient from "./clients/AddClient";
import EditClient from "./clients/EditClient";
import ActivateClient from "./clients/ActivateClient";

// DAO
import clientDAO from "../database/clients";

// Others
import { bus } from "../main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "clients",
  components: {
    ReorderClient,
    AddClient,
    EditClient,
    ActivateClient,
    Loading,
  },
  data() {
    return {
      selClient: {},
      allClientsObj: {},
      items: [],
      fields: [
        { key: "order", label: "Order", sortable: true },
        { key: "clientName", label: "Client", sortable: true },
        { key: "dateUpdated", label: "Date updated" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      eventBus: bus,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, 50, 75, 100],

      isLoading: false,
    };
  },
  computed: {
    exportData() {
      let allClientsArr = [];
      this.items.forEach((item) => {
        let client = Object.assign({}, item);
        client.isActive = item.isActive ? "Active" : "Inactive";
        allClientsArr.push(client);
      });
      return allClientsArr;
    },
  },

  async mounted() {
    // pre-load clients
    await this.getAllClients();

    bus.$on("onCloseReOrderClient", () => this.getAllClients());
    bus.$on("onCloseAddClient", () => this.getAllClients());
    bus.$on("onCloseEditClient", () => this.getAllClients());
    bus.$on("onCloseActivateClient", () => this.getAllClients());
  },

  methods: {
    async getAllClients() {
      try {
        this.isLoading = true;
        this.allClientsObj = await clientDAO.retrieveAll();
        this.items = Object.values(this.allClientsObj);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    updateSelClient(item) {
      this.selClient = item;
      this.$store.commit("client/SET_CURR_CLIENT", item);
      bus.$emit("onUpdateSelClient", item);
    },
  },

  beforeDestroy() {
    bus.$off("onCloseAddClient");
    bus.$off("onCloseEditClient");
    bus.$off("onCloseActivateClient");
  },
};
</script>
