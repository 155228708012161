import { db } from '../config/firebase';

// Util
import formatUtil from '../utils/formatUtil';

async function retrieveAll() {
  let query = db.collection("clients").orderBy('order', 'asc');

  const querySnapshot = await query.get();

  let results = [];
  querySnapshot.forEach(doc => {
    results.push({
      id: doc.id,
      ...doc.data()
    });
  });

  return results;
}

async function createClient(client) {
  let query = db.collection("clients");
  return await query.add(client);
}

async function updateClient(client, id) {
  // cleanup before saving
  client = formatUtil.cleanupFields(client);

  let query = db.collection("clients").doc(id);
  return await query.set(client, { merge: true });
}

async function deleteClient(id) {
  let query = db.collection("clients").doc(id);
  return await query.delete();
}

export default {
  retrieveAll,
  createClient,
  updateClient,
  deleteClient,
}